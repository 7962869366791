import * as React from 'react';
import { Dialog } from '@dx-ui/osc-dialog';

type AlertDialog = {
  /**
   * text for the button
   */
  label: string;
} & Dialog;

/**
 * wraps the `<Dialog>` component and a button to be used in the alert
 */
export const AlertDialog: React.FC<React.PropsWithChildren<AlertDialog>> = ({
  label,
  children,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <button className="btn btn-primary-link" onClick={() => setOpen(true)} type="button">
        {label}
      </button>
      <Dialog {...rest} isOpen={open} onDismiss={() => setOpen(!open)}>
        {children}
      </Dialog>
    </>
  );
};

export default AlertDialog;
